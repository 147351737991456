export const Banner = () => import('../../components/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const CreateYourOwn = () => import('../../components/CreateYourOwn.vue' /* webpackChunkName: "components/create-your-own" */).then(c => wrapFunctional(c.default || c))
export const EditAddress = () => import('../../components/EditAddress.vue' /* webpackChunkName: "components/edit-address" */).then(c => wrapFunctional(c.default || c))
export const HalfNHalf = () => import('../../components/HalfNHalf.vue' /* webpackChunkName: "components/half-n-half" */).then(c => wrapFunctional(c.default || c))
export const Menu = () => import('../../components/Menu.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c))
export const Orders = () => import('../../components/Orders.vue' /* webpackChunkName: "components/orders" */).then(c => wrapFunctional(c.default || c))
export const Product = () => import('../../components/Product.vue' /* webpackChunkName: "components/product" */).then(c => wrapFunctional(c.default || c))
export const Rating = () => import('../../components/Rating.vue' /* webpackChunkName: "components/rating" */).then(c => wrapFunctional(c.default || c))
export const StoreDetail = () => import('../../components/StoreDetail.vue' /* webpackChunkName: "components/store-detail" */).then(c => wrapFunctional(c.default || c))
export const StoreModal = () => import('../../components/StoreModal.vue' /* webpackChunkName: "components/store-modal" */).then(c => wrapFunctional(c.default || c))
export const TopsLogo = () => import('../../components/TopsLogo.vue' /* webpackChunkName: "components/tops-logo" */).then(c => wrapFunctional(c.default || c))
export const AuthForgotPassword = () => import('../../components/auth/ForgotPassword.vue' /* webpackChunkName: "components/auth-forgot-password" */).then(c => wrapFunctional(c.default || c))
export const AuthLogin = () => import('../../components/auth/Login.vue' /* webpackChunkName: "components/auth-login" */).then(c => wrapFunctional(c.default || c))
export const AuthRegister = () => import('../../components/auth/Register.vue' /* webpackChunkName: "components/auth-register" */).then(c => wrapFunctional(c.default || c))
export const Cart = () => import('../../components/cart/Cart.vue' /* webpackChunkName: "components/cart" */).then(c => wrapFunctional(c.default || c))
export const CartItem = () => import('../../components/cart/CartItem.vue' /* webpackChunkName: "components/cart-item" */).then(c => wrapFunctional(c.default || c))
export const CartItemAddedModal = () => import('../../components/cart/CartItemAddedModal.vue' /* webpackChunkName: "components/cart-item-added-modal" */).then(c => wrapFunctional(c.default || c))
export const CartFreeProduct = () => import('../../components/cart/FreeProduct.vue' /* webpackChunkName: "components/cart-free-product" */).then(c => wrapFunctional(c.default || c))
export const CartIngredients = () => import('../../components/cart/Ingredients.vue' /* webpackChunkName: "components/cart-ingredients" */).then(c => wrapFunctional(c.default || c))
export const CartSetQty = () => import('../../components/cart/SetQty.vue' /* webpackChunkName: "components/cart-set-qty" */).then(c => wrapFunctional(c.default || c))
export const CustomerDetail = () => import('../../components/customer/Detail.vue' /* webpackChunkName: "components/customer-detail" */).then(c => wrapFunctional(c.default || c))
export const CustomerPasswordChange = () => import('../../components/customer/PasswordChange.vue' /* webpackChunkName: "components/customer-password-change" */).then(c => wrapFunctional(c.default || c))
export const CustomizerDeal = () => import('../../components/customizer/Deal.vue' /* webpackChunkName: "components/customizer-deal" */).then(c => wrapFunctional(c.default || c))
export const CustomizerHalfNHalf = () => import('../../components/customizer/HalfNHalf.vue' /* webpackChunkName: "components/customizer-half-n-half" */).then(c => wrapFunctional(c.default || c))
export const CustomizerPizza = () => import('../../components/customizer/Pizza.vue' /* webpackChunkName: "components/customizer-pizza" */).then(c => wrapFunctional(c.default || c))
export const CustomizerProduct = () => import('../../components/customizer/Product.vue' /* webpackChunkName: "components/customizer-product" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/header/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const HeaderTop = () => import('../../components/header/HeaderTop.vue' /* webpackChunkName: "components/header-top" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/footer/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FooterAbout = () => import('../../components/footer/FooterAbout.vue' /* webpackChunkName: "components/footer-about" */).then(c => wrapFunctional(c.default || c))
export const FooterAccount = () => import('../../components/footer/FooterAccount.vue' /* webpackChunkName: "components/footer-account" */).then(c => wrapFunctional(c.default || c))
export const FooterPolicy = () => import('../../components/footer/FooterPolicy.vue' /* webpackChunkName: "components/footer-policy" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
